import { styled } from '@mui/system';
import Main from './Main';
import Statistics from './Statistics';
// import HistoricalWarnings from './HistoricalWarnings';
import { CustomTheme } from 'Config/Theme/Common/CustomTheme.types';

interface ScraperProps {
  theme: CustomTheme;
}

const RootContainer = styled('div')<{ theme: CustomTheme }>(({ theme }) => ({
  background: theme.palette.background.main,
  width: '60vw',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxHeight: '100vh',
}));

function Scraper({ theme }: ScraperProps) {
  return (
    <RootContainer theme={theme}>
      <Main />
      <Statistics />
      {/* <HistoricalWarnings /> */}
    </RootContainer>
  );
}

export default Scraper;
