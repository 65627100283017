import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface WithoutVariant extends Omit<TextFieldProps, 'variant'> {}

function CustomTextField({ InputProps, InputLabelProps, ...otherProps }: WithoutVariant): JSX.Element {
  return (
    <TextField
      variant='outlined'
      InputProps={{
        ...InputProps,
        classes: {
          adornedEnd: 'adornedEnd',
          input: 'input',
          multiline: 'multiline',
          notchedOutline: 'notchedOutline',
          focused: 'focused',
          ...(InputProps?.classes ?? {})
        }
      }}
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          focused: 'labelFocused',
          outlined: 'labelOutlined',
          ...(InputLabelProps?.classes ?? {})
        }
      }}
      {...otherProps}
    />
  );
}

export default CustomTextField;
