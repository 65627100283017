interface FieldDictionaryIconProps {
    width?: string
    height?: string
}

export default function FieldDictionaryIcon({ width, height }: FieldDictionaryIconProps) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width ?? '20'}
            height={height ?? '20'}
            viewBox='0 0 20 20'
            fill='none'
        >
            <g clipPath='url(#clip0_1939_124206)'>
                <path
                    d='M6.66683 13.3333H13.3335V15H6.66683V13.3333ZM6.66683 9.99999H13.3335V11.6667H6.66683V9.99999ZM11.6668 1.66666H5.00016C4.0835 1.66666 3.3335 2.41666 3.3335 3.33332V16.6667C3.3335 17.5833 4.07516 18.3333 4.99183 18.3333H15.0002C15.9168 18.3333 16.6668 17.5833 16.6668 16.6667V6.66666L11.6668 1.66666ZM15.0002 16.6667H5.00016V3.33332H10.8335V7.49999H15.0002V16.6667Z'
                    fill='currentColor'
                />
            </g>
            <defs>
                <clipPath id='clip0_1939_124206'>
                    <rect width={width ?? '20'} height={height ?? '20'} fill='currentColor' />
                </clipPath>
            </defs>
        </svg>
    )
}
