import { Typography } from '@mui/material';
import { styled } from '@mui/system';

interface ScraperStatisticsProps {
  scraperStatistics: {
    icon: JSX.Element;
    label: string;
    value: number | string;
    amount: number | string;
  }[];
}

const RootContainer = styled('div')`
  padding: 18px 32px;
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap; /* for smaller screen sizes */
`;

const Title = styled(Typography)`
  margin-right: 26px;
`;

const ScraperStatisticContainer = styled('div')`
  padding: 0px 8px;
  display: flex;
  color: ${({ theme }) => theme.palette.text.primary};
  align-items: center;
`;

const ScraperStatisticIcon = styled('div')`
  display: flex;
  margin-right: 8px;
  align-self: center;
`;

function ScraperStatistic(props: {
  scraperStatistic: { icon: JSX.Element; label: string; value: number | string; amount: number | string };
}) {
  const { scraperStatistic } = props;
  const formattedValue = typeof scraperStatistic.value === 'number' ? scraperStatistic.value.toFixed(1) : scraperStatistic.value;

  return (
    <ScraperStatisticContainer>
      <ScraperStatisticIcon>{scraperStatistic.icon}</ScraperStatisticIcon>
      <Typography variant="body1">
        {scraperStatistic.label} = {formattedValue}% ({scraperStatistic.amount})
      </Typography>
    </ScraperStatisticContainer>
  );
}


function ScraperStatistics(props: ScraperStatisticsProps) {
  const { scraperStatistics } = props;
  return (
    <RootContainer>
      <Title color="textPrimary">AUTHORITY STATISTICS</Title>
      {scraperStatistics.map((scraperStatistic) => (
        <ScraperStatistic
          key={scraperStatistic.label}
          scraperStatistic={scraperStatistic}
        />
      ))}
    </RootContainer>
  );
}

export default ScraperStatistics;
