import Sidebar from 'components/Sidebar'
import ScraperOverviewIcon from 'components/CustomIcons/ScraperOverview'
import FieldDictionaryIcon from 'components/CustomIcons/FieldDictionary'
// import NoteworthyScrapersIcon from 'components/CustomIcons/NoteworthyScrapers'
// import NewsIcon from 'components/CustomIcons/News'
import ScraperOverview from 'components/ScraperOverview'
import FieldDictionary from 'components/FieldDictionary'
// import NoteworthyScrapers from 'components/NoteworthyScrapers'
// import News from 'components/News'

import { Provider } from 'react-redux'
import store from 'redux/store'
import { darkTheme } from 'Config/theme'
import { BrowserRouter as Router } from 'react-router-dom'
import Scraper from 'components/ScraperOverview/Scraper'
import { ThemeProvider, useTheme } from '@mui/material'
import { CustomTheme } from 'Config/Theme/Common/CustomTheme.types'
import { useEffect } from 'react'


function App() {
    const theme: CustomTheme = useTheme();
    
const routes = {
    ScraperOverview: {
        title: 'Authority Overview',
        route: '/authority_overview',
        icon: <ScraperOverviewIcon  />,
        element: <ScraperOverview />,
        isTab: true,
    },
    FieldDictionary: {
        title: 'Field Dictionary',
        route: '/field_dictionary',
        icon: <FieldDictionaryIcon />,
        element: <FieldDictionary />,
        isTab: true,
    },
    // NoteworthyScrapers: {
    //     title: 'Noteworthy Scrapers',
    //     route: '/noteworthy_scrapers',
    //     icon: <NoteworthyScrapersIcon />,
    //     element: <NoteworthyScrapers />,
    //     isTab: true,
    // },
    // News: { title: 'News', route: '/news', icon: <NewsIcon />, element: <News />, isTab: true },
    Scraper: { title: '', route: '/authority_overview/:scraperName',  icon: <></>, element: <Scraper theme={theme}/>,  isTab: false },
}
useEffect(() => {
    const getMemoryUsage = () => {
      if (window.performance && (window.performance as any).memory) {
        const memoryInfo = (window.performance as any).memory;
        const used = memoryInfo.usedJSHeapSize / 1024 / 1024;
        console.log(`Memory usage: ${Math.round(used * 100) / 100} MB`);
      }
    };

    getMemoryUsage();
  }, []);
    return (
        <div style={{ height: '100vh' }}>
            <Router>
                <ThemeProvider theme={darkTheme}>
                    <Provider store={store}>
                        <Sidebar routes={routes} />
                    </Provider>
                </ThemeProvider>
            </Router>
        </div>
    )
}

export default App
