import ScraperStatistics from './ScraperStatistics'
import ErrorIcon from '@mui/icons-material/Error'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CheckBox from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'
import {
    Box,
    Divider,
    InputAdornment,
    MenuItem,
    Typography,
    TextField,
    Button,
    Popover,
    useTheme,
    LinearProgress,
} from '@mui/material'
import { DataGrid, GridColDef, GridSortModel, useGridApiRef } from '@mui/x-data-grid'
import SearchIcon from '@mui/icons-material/Search'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
// import { createStyles, makeStyles } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'redux/store'
import {
    fetchScraperOverview,
    setScraperStatusFilter,
} from 'redux/scraperOverview/scraperOverviewSlice'
import { ChangeEvent, memo, useEffect, useMemo, useState } from 'react'
import SelectTextField from 'components/Common/UI/SelectTextField'
import ColumnOptionsOverlay from './ColumnOptionsOverlay'
import CustomPagination from 'components/Common/UI/CustomPagination'
// import { CustomTheme } from 'Config/Theme/Common/CustomTheme.types'
import moment from 'moment'
import { stoneGrey } from 'Config/Theme/Common/Palette'

const scraperStatusFilters = ['failing', 'warning', 'ok', 'inactive', 'none']

const defaultColumns = ['last status', 'name', 'last_run', 'status', 'coverage', 'days_behind', 'min_coverage_date', 'max_coverage_date', 'num_full_applications', 'num_uid_applications']


const columns: GridColDef[] = [
    {
        field: 'last status',
        headerName: '',
        renderCell: (params) => {
            switch (params.row.status) {
                case 'ok':
                case 'OK':
                    return <CheckCircleIcon sx={{ color: '#A9FE95' }} />
                case 'Warning':
                case 'WARNING':
                    return <WarningIcon sx={{ color: '#FFE5A0' }} />
                case 'Failing':
                case 'FAILING':
                    return <ErrorIcon sx={{ color: '#FF7981' }} />
                case 'Inactive':
                case 'INACTIVE':
                    return <CancelIcon sx={{ color: '#C4C4C4' }} />
                case 'None':
                case 'NONE':
                    return <DoNotDisturbOnIcon sx={{ color: '#C4C4C4' }} />
                default:
                    return <></>
            }
            
        },
        flex: 1,
        minWidth: 50
    },
    {
        field: 'name',
        headerName: 'Authority Name',
        flex: 2,
        minWidth: 150,
        sortable: true,
        renderCell(params) {
            return (
                <a color='white' href={`/authority_overview/${params.row.scraper_name}`}>
                    {params.value}
                </a>
            )
        },
    },
    {
        field: 'last_run',
        headerName: 'Last Run',
        flex: 3,
        minWidth: 150,
        valueGetter(params) {
            return params?.value ? moment(params.value).format('YYYY-MM-DD HH:MM:SS') : ''
        },
    },
    { field: 'status', headerName: 'Last Status', flex: 2, minWidth: 100, },
    {
        field: 'coverage',
        headerName: 'Coverage',
        flex: 1,
        minWidth: 80,
        valueGetter(params) {
        let coverage = typeof params?.value === 'number' ? params.value : 0;
        if (coverage > 100) {
        coverage = 100;
        }
        return coverage;
        },
        valueFormatter: (params) => `${(params.value.toFixed(1))}%`,
        },
    { field: 'days_behind', headerName: 'Days Behind', flex: 1, minWidth: 100, },
    {
        field: 'old_percent',
        headerName: 'Old Percent',
        flex: 1,
        minWidth: 100,
        valueGetter(params) {
            return typeof params?.value === 'number' ? `${params.value.toFixed(1)}%` : ''
        },
    },
    {
        field: 'min_target_date',
        headerName: 'Target Min Date',
        flex: 2,
        minWidth: 140,
        valueGetter(params) {
            return params?.value ? moment(params.value).format('YYYY-MM-DD') : ''
        },
    },
    {
        field: 'max_target_date',
        headerName: 'Target Max Date',
        flex: 2,
        minWidth: 140,
        valueGetter(params) {
            return params?.value ? moment(params.value).format('YYYY-MM-DD') : ''
        },
    },
    {
        field: 'min_coverage_date',
        headerName: 'Coverage Min Date',
        flex: 2,
        minWidth: 140,
        valueGetter(params) {
            return params?.value ? moment(params.value).format('YYYY-MM-DD') : ''
        },
    },
    {
        field: 'max_coverage_date',
        headerName: 'Coverage Max Date',
        flex: 2,
        minWidth: 140,
        valueGetter(params) {
            return params?.value ? moment(params.value).format('YYYY-MM-DD') : ''
        },
    },
    { field: 'num_full_applications', headerName: 'Num Full', flex: 1, minWidth: 80 },
    { field: 'num_uid_applications', headerName: 'Num UID Applications', flex: 1, minWidth: 80 },
    { field: 'num_full_failing', headerName: 'Num Full Failing', flex: 1, minWidth: 80 },
    { field: 'num_full_failed', headerName: 'Num Full Failed', flex: 1, minWidth: 80 },
    { field: 'num_uid_failing', headerName: 'Num UID Failing', flex: 1, minWidth: 80 },
    { field: 'num_uid_failed', headerName: 'Num UID Failed', flex: 1, minWidth: 80 },
    { field: 'warnings', headerName: 'Warnings', flex: 5, minWidth: 250 },
    { field: 'internal_comments', headerName: 'Internal Comments', flex: 5, minWidth: 150 },
]

function ScraperOverview() {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [scraperName, setScraperName] = useState<string>('')
    // const classes = useStyles()
    const dispatch = useDispatch<AppDispatch>()
    const scraperStatusFilter = useSelector(
        (state: RootState) => state.scraperOverview.scraperStatusFilter,
    )
    const rows = useSelector((state: RootState) => state.scraperOverview.scraperOverviewData)
    const status = useSelector((state: RootState) => state.scraperOverview.status)
    console.log(rows, 'rows');
    

    const scraperStatistics = useMemo(
        () => [
            {
                icon: <ErrorIcon sx={{ color: '#FF7981' }} />,
                label: 'Failing',
                value: (
                    (rows?.filter((e: any) => e.status?.toLowerCase() === 'failing').length *
                        100) /
                    rows?.length
                ).toFixed(1),
                amount: (
                    (rows?.filter((e: any) => e.status?.toLowerCase() === 'failing').length)
                    ),
            },
            {
                icon: <WarningIcon sx={{ color: '#FFE5A0' }} />,
                label: 'Warning',
                value: (
                    (rows?.filter((e: any) => e.status?.toLowerCase() === 'warning').length *
                        100) /
                    rows?.length
                ).toFixed(1),
                amount: (
                    (rows?.filter((e: any) => e.status?.toLowerCase() === 'warning').length)
                    ),
            },
            {
                icon: <CheckCircleIcon sx={{ color: '#A9FE95' }} />,
                label: 'Ok',
                value: (
                    (rows?.filter((e: any) => e.status?.toLowerCase() === 'ok').length * 100) /
                    rows?.length
                ).toFixed(1),
                amount: (
                    (rows?.filter((e: any) => e.status?.toLowerCase() === 'ok').length)
                    ),
            },
            {
                icon: <CancelIcon sx={{ color: '#C4C4C4' }} />,
                label: 'Inactive',
                value: (
                    (rows?.filter((e: any) => e.status?.toLowerCase() === 'inactive').length *
                        100) /
                    rows?.length
                ).toFixed(1),
                amount: (
                    (rows?.filter((e: any) => e.status?.toLowerCase() === 'inactive').length)
                    ),
            },
            {
                icon: <DoNotDisturbOnIcon sx={{ color: '#C4C4C4' }} />,
                label: 'None',
                value: (
                    (rows?.filter((e: any) => e.status?.toLowerCase() === 'none').length *
                        100) /
                    rows?.length
                ).toFixed(1),
                amount: (
                    (rows?.filter((e: any) => e.status?.toLowerCase() === 'none').length)
                    ),
            },
        ],
        [rows],
    )

    function handleScraperStatusFilterChange(e: any) {
        dispatch(setScraperStatusFilter(e.target.value))
    }

    const dataGridRef = useGridApiRef()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleScraperNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setScraperName(value);
      
        // Check if the search string is empty
        if (value.trim() === '') {
          setScraperName(''); // Reset the search string to empty
        }
      };
      
      console.log(dataGridRef, 'current data', dataGridRef);
      

    function renderSelected(selected: any) {
        if (selected.length === 0 || selected.length < 5 ) {
            return 'ANY';
          } else {
            return `${selected.length} SELECTED`;
          }
    }
    
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);


    const dataGridRows = useMemo(
        () =>
            rows?.filter((row: { status: string; scraper_name: string }) => {
                if (scraperStatusFilter.length === 0) {
                    return (
                        (scraperName
                            ? row.scraper_name?.toLowerCase().startsWith(scraperName.toLowerCase())
                            : true) &&
                        row.scraper_name?.trim() !== ''
                    );
                } else {
                    return (
                        scraperStatusFilter.includes(row.status?.toLowerCase()) &&
                        (scraperName
                            ? row.scraper_name?.toLowerCase().startsWith(scraperName.toLowerCase())
                            : true) &&
                        row.scraper_name?.trim() !== ''
                    );
                }
            }),
        [rows, scraperStatusFilter, scraperName]
    );
    


      console.log(dataGridRows, 'search rows');
      

    const theme = useTheme()

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0,
    })

    useEffect(() => {
        dispatch(fetchScraperOverview())
    }, [])
    
    useEffect(() => {
        const dataGrid = dataGridRef.current
        if (dataGrid) {
            columns.map((column) => {
                dataGrid.setColumnVisibility(column.field, defaultColumns.includes(column.field))
            })
        }
    }, [dataGridRows])

    console.log(scraperStatusFilter, 'filters');

    return (
        <>
            <ScraperStatistics scraperStatistics={scraperStatistics} />
            {status ? (
                <LinearProgress color='primary' />
            ) : (
                <Box sx={{
                    background: theme.palette.background.paper,
                    borderRadius: '4px',
                    padding: '28px 32px',
                    marginTop: '18px',
                    maxWidth: '100%',
                    '& a': {
                        color: theme.palette.text.primary,
                    },
                    displat: 'flex',
                    flexWrap: 'wrap',
                }}>
                    <Box p={2} display='flex' justifyContent='space-between' flexWrap='wrap'>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography color='white' sx={{marginRight: '32px !important'}}>
                                STATUS
                            </Typography>
                            <SelectTextField
                            customPlaceholderColor={''}
                                placeholder='Any'
                                multi
                                sx={{
                                    paddingTop: '4px',
                                    width: 312,
                                    height: '40px !important',
                                    color: `${theme.palette.info.main} !important`,
                                    '&:focus': {
                                        outline: 'none',
                                      },
                                      '&:focus-within': {
                                        outline: 'none',
                                      },
                                      '& .MuiInputBase-root': {
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: stoneGrey
                                        },
                                    },
                                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: `${theme.palette.secondary.main}`,
                                      },
                                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'white',
                                      },
                                      '& .MuiSelect-select': {
                                        color: `${theme.palette.info.main} !important`,
                                        border: `1px solid ${stoneGrey}`,
                                      },
                                      '& .MuiSelect-icon': {
                                        color: `${theme.palette.text.primary} !important`,
                                      },
                                    }}
                                id='demo-select-small'
                                size='small'
                                value={scraperStatusFilter}
                                onChange={handleScraperStatusFilterChange}
                                SelectProps={{
                                    displayEmpty: true,
                                    multiple: true,
                                    renderValue: renderSelected,
                                }}
                            >
                                            {scraperStatusFilters.map((e, index) => (
                                                <MenuItem
                                                    key={e}
                                                    value={e}
                                                    sx={{
                                                        display: 'flex !important',
                                                        justifyContent: 'space-between !important',
                                                        gap: 8,
                                                        '&.Mui-selected': {
                                                            backgroundColor: 'rgba(255, 255, 255, 0.16)',
                                                            borderBottom: 'none !important',
                                                            marginBottom: '1px',
                                                        },
                                                        '&.Mui-selected:hover': {
                                                            backgroundColor: 'rgba(255, 255, 255, 0.16)',
                                                        },
                                                        '& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected ': {
                                                            backgroundColor: 'rgba(255, 255, 255, 0.16)'
                                                        },
                                                        borderBottom: index !== scraperStatusFilters.length - 1 ? '1px solid #D9D9D9' : 'none',
                                                        margin: '0px 4px'
                                                    }}
                                                >
                                                    <Typography>{e.charAt(0).toUpperCase() + e.slice(1)}</Typography>
                                                    {scraperStatusFilter?.filter((el) => e === el)?.length > 0 ? (
                                                        <CheckBox sx={{ color: `${theme.palette.secondary.main} !important` }} />
                                                    ) : (
                                                        <CheckBoxOutlineBlank />
                                                    )}
                                                </MenuItem>
                                ))}
                            </SelectTextField>
                        </Box>
                        <Box
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            flexWrap='wrap'
                        >
                            <TextField
                                variant='outlined'
                                sx={{
                                    '& .MuiInputBase-root': {
                                        background: 'white',
                                        color: theme.palette.text.primary,
                                        width: 500,
                                        // padding: '24px 20px 16px 20px',
                                        marginRight: '20px !important',
                                    },
                                    '& .MuiInputBase-input': {
                                        color: `${theme.palette.info.main} !important`,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        paddingRight: '4px',
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                      },
                                      '&:focus-visible': {
                                        outline: 'none !important',
                                        borderColor: `${theme.palette.secondary.main}`
                                      },
                                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: `${theme.palette.secondary.main}`,
                                      },
                                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'white',
                                      },
                                      '& .MuiInputBase-root-MuiOutlinedInput-root:focus': {
                                        outlineColor: `${theme.palette.secondary.main}`,
                                    }
                                }}
                                id='input-with-icon-textfield'
                                placeholder='Search for an authority name'
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={scraperName}
                                onChange={handleScraperNameChange}
                            />
                            <Button
                                variant='contained'
                                sx={{
                                    maxWidth: '40px !important',
                                    maxHeight: '40px !important',
                                    minWidth: '40px !important',
                                    minHeight: '40px !important',
                                    backgroundColor: `${theme.palette.secondary.main} !important`,
                                    '&:hover': {
                                        opacity: 0.5,
                                    },
                                }}
                                onClick={handleClick}
                            >
                                <MoreVertIcon style={{ color: 'black' }}/>
                            </Button>
                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <ColumnOptionsOverlay
                                    dataGridRef={dataGridRef.current}
                                    columns={columns}
                                />
                            </Popover>
                        </Box>
                    </Box>
                    <Divider />
                    <DataGrid
                        apiRef={dataGridRef}
                        sx={{
                            border: 'none',
                            color: 'white',
                            maxHeight: '70vh',
                            overflow: 'auto',
                            width: '100%',
                            lineHeight: '24px',
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                width: 8,
                            },
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                                background: theme.palette.grey[600],
                            },
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.grey[500],
                            },
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                                background: theme.palette.background.default,
                            },
                            '& .MuiDataGrid-menu': {
                                background: theme.palette.background.default,
                                '& .MuiPaper-root': {
                                    background: theme.palette.background.default,
                                },
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none !important'
                            },
                            '& .MuiDataGrid-root:focus-within': {
                                outline: 'none !important'
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                overflowY: 'scroll !important',
                                overflowX: 'auto !important',
                            },
                            '& .custom-warnings-header': {
                                lineHeight: '16px',
                            },
                            '& .custom-warnings-cell': {
                                lineHeight: '16px',
                            },
                            '& .MuiDataGrid-withBorderColor': {
                                borderColor: stoneGrey,
                            },
                        }}
                        getRowId={(e) => e.scraper_name}
                        rows={dataGridRows ?? []}
                        columns={columns.map((column) => ({
                            ...column,
                            headerClassName: column.field === 'warnings' ? 'custom-warnings-header' : undefined,
                            cellClassName: column.field === 'warnings' ? 'custom-warnings-cell' : undefined,
                        }))}
                        disableRowSelectionOnClick
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 25, rows?.length < 100 ? rows?.length : 100]}
                        slots={{
                            pagination: CustomPagination,
                        }}
                        columnBuffer={8}
                        // getRowHeight={() => 'auto'}
                        rowHeight={35}
                        autoHeight={true}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                    />
                </Box>
            )}
        </>
    )
}

export default memo(ScraperOverview)
