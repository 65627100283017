import { Typography, Grid, Divider } from '@mui/material';
import { styled } from '@mui/system';
import HouseIcon from '@mui/icons-material/House';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchScraperAppSize, fetchScraperAppState, fetchScraperAppType, fetchScraperDefaultGeocodeMethod, fetchScraperGeocodeAccuracy, fetchScraperGeocodeMethod, fetchScraperOverviewName, fetchScraperStartDate } from 'redux/scraperOverview/scraperOverviewSlice';
import { AppDispatch, RootState } from 'redux/store';
import StatisticsTable from './StatisticsTable';

// interface StatisticsProps {}

const RootContainer = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  marginTop: 36,
  padding: '20px 36px 36px 36px',
}));

const Header = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 30,
  display: 'flex',
  alignItems: 'center',
}));

const ScraperHeading = styled(Typography)({
  marginLeft: '28px !important',
  fontSize: '30px !important',
});

const DividerStyled = styled(Divider)({
  marginTop: '20px !important',
  borderColor: 'white',
  opacity: '16%',
});

const Body = styled('div')({
  marginTop: '36px !important',
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const TotalPlanningApplicationsContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[500]}`,
  color: theme.palette.text.primary,
  padding: 12,
  borderRadius: '4px !important',
}));

const TotalPlanningApplicationsHeader = styled(Typography)({
  fontSize: 30,
});

const TotalPlanningApplicationsContent = styled('div')({
  marginTop: 8,
  display: 'flex',
  fontSize: '60px !important',
  '& p': {
    fontSize: 60,
    marginLeft: 24,
    marginTop: -16,
  },
});

function TotalPlanningApplications() {
  const { scraperOverviewNameData } = useSelector((state: RootState) => state.scraperOverview);
  return (
    <TotalPlanningApplicationsContainer>
      <TotalPlanningApplicationsHeader>Total planning applications</TotalPlanningApplicationsHeader>
      <TotalPlanningApplicationsContent>
        <HouseIcon fontSize="inherit" />
        <Typography>{scraperOverviewNameData?.num_full_applications ? scraperOverviewNameData?.num_full_applications.toLocaleString() : ''}</Typography>
      </TotalPlanningApplicationsContent>
    </TotalPlanningApplicationsContainer>
  );
}

const createRow = (
  identifier: string,
  planningApplicationsNumber: number,
  percentageOfTotal: number,
) => ({ identifier: identifier, planningApplicationsNumber, percentageOfTotal });

function Statistics() {
  const { scraperName } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (scraperName) {
      dispatch(fetchScraperAppSize(scraperName));
      dispatch(fetchScraperAppState(scraperName));
      dispatch(fetchScraperAppType(scraperName));
      dispatch(fetchScraperGeocodeMethod(scraperName));
      dispatch(fetchScraperGeocodeAccuracy(scraperName));
      dispatch(fetchScraperStartDate(scraperName));
      dispatch(fetchScraperDefaultGeocodeMethod(scraperName));
      dispatch(fetchScraperOverviewName(scraperName as string));
    }
  }, [scraperName]);

  const scraperAppSizeDetails = useSelector((state: RootState) => state.scraperOverview.scraperNameAppSizeDetails);
  const typeDataAppSize = scraperAppSizeDetails != null ? {
    headers: [
      { key: 'identifier', headerName: 'Type' },
      { key: 'planningApplicationsNumber', headerName: '# Planning applications' },
      { key: 'percentageOfTotal', headerName: 'Percentage of total' },
    ],
    rows: scraperAppSizeDetails
      .filter((item) => item.app_size !== null)
      .sort((a, b) => {
        const order = ['small', 'medium', 'large', 'unknown'];
        const aIndex = typeof a.app_size === 'string' && order.indexOf(a.app_size.toLowerCase()) !== -1 ? order.indexOf(a.app_size.toLowerCase()) : 4;
        const bIndex = typeof b.app_size === 'string' && order.indexOf(b.app_size.toLowerCase()) !== -1 ? order.indexOf(b.app_size.toLowerCase()) : 4;
        return aIndex - bIndex;
      })
      .map((item) => {
        if (typeof item.app_size === 'string') {
          return createRow(item.app_size, item.count, item.percent);
        }
        return null;
      })
      .filter((item): item is { identifier: string; planningApplicationsNumber: number; percentageOfTotal: number } => item !== null), // Filter out null values
  } : null;

  const scraperAppStateDetails = useSelector((state: RootState) => state.scraperOverview.scraperNameAppStateDetails);
  const typeDataAppState = scraperAppStateDetails != null ? {
    headers: [
      { key: 'identifier', headerName: 'Consent' },
      { key: 'planningApplicationsNumber', headerName: '# Planning applications' },
      { key: 'percentageOfTotal', headerName: 'Percentage of total' },
    ],
    rows: scraperAppStateDetails.map((item) => createRow(item.app_state ?? 'Unknown', item.count, item.percent)),
  } : null;

  const scraperAppTypeDetails = useSelector((state: RootState) => state.scraperOverview.scraperNameAppTypeDetails);
  const typeDataApptype = scraperAppTypeDetails != null ? {
    headers: [
      { key: 'identifier', headerName: 'Type' },
      { key: 'planningApplicationsNumber', headerName: '# Planning applications' },
      { key: 'percentageOfTotal', headerName: 'Percentage of total' },
    ],
    rows: scraperAppTypeDetails.map((item) => createRow(item.app_type ?? 'Unknown', item.count, item.percent)),
  } : null;

  const scraperGeocodeMethodDetails = useSelector((state: RootState) => state.scraperOverview.scraperNameAppGeocodeMethodDetails);
  const typeDataGeocodeMethod = scraperGeocodeMethodDetails != null ? {
    headers: [
      { key: 'identifier', headerName: 'Geocoding Mth' },
      { key: 'planningApplicationsNumber', headerName: '# Planning applications' },
      { key: 'percentageOfTotal', headerName: 'Percentage of total' },
    ],
    rows: scraperGeocodeMethodDetails.map((item) =>
      createRow(
        item?.new_geocode_method
          ?.replace('dpa_3', 'Delivery Point Address (ver.3)')
          .replace('dpa_4', 'Delivery Point Address (ver.4)')
          .replace('lpi_3', 'Land Property Identifier (ver.3)')
          .replace('lpi_ao_3', 'Land Property Identifier from Addressable Object (ver.3)')
          .replace('lpi_ao_4', 'Land Property Identifier from Addressable Object (ver.4)')
          .replace('lpi_ao_5', 'Land Property Identifier from Addressable Object (ver.5)')
          .replace('lpi_street_4', 'Land Property Identifier from Street (ver.4)')
          .replace('street_3', 'Street (ver.3)')
          .replace('street_4', 'Street (ver.4)')
          ?? 'Unknown',
        item.count,
        item.percent
      )
    ),
  } : null;

  const scraperGeocodeAccuracyDetails = useSelector((state: RootState) => state.scraperOverview.scraperNameAppGeocodeAccuracyDetails);
  const typeDataGeocodeAccuracy = scraperGeocodeAccuracyDetails != null ? {
    headers: [
      { key: 'identifier', headerName: 'Geocoding Acc' },
      { key: 'planningApplicationsNumber', headerName: '# Planning applications' },
      { key: 'percentageOfTotal', headerName: 'Percentage of total' },
    ],
    rows: scraperGeocodeAccuracyDetails
      .filter((item) => item.new_geocode_accuracy !== null)
      .sort((a, b) => {
        const order = ['50%+', '60%+', '70%+', '80%+', '90%+', '100%+'];
        const aIndex = typeof a.new_geocode_accuracy === 'string' ? order.indexOf(a.new_geocode_accuracy) : -1;
        const bIndex = typeof b.new_geocode_accuracy === 'string' ? order.indexOf(b.new_geocode_accuracy) : -1;
        return aIndex - bIndex;
      })
      .map((item) => {
        if (typeof item.new_geocode_accuracy === 'string') {
          return createRow(item.new_geocode_accuracy, item.count, item.percent);
        }
        return null;
      })
      .filter((item): item is { identifier: string; planningApplicationsNumber: number; percentageOfTotal: number } => item !== null), // Filter out null values
  } : null;

  const scraperStartDateDetails = useSelector((state: RootState) => state.scraperOverview.scraperNameAppStartDateDetails);
  const typeDataStartDate = scraperStartDateDetails != null ? {
    headers: [
      { key: 'identifier', headerName: 'Time Period' },
      { key: 'planningApplicationsNumber', headerName: '# Planning applications' },
      { key: 'percentageOfTotal', headerName: 'Percentage of total' },
    ],
    rows: scraperStartDateDetails
      .filter((item) => item.start_date !== null)
      .sort((a, b) => {
        const order = ['1 Month', '3 Months', '6 Months', '1 Year', '3 Years'];
        const aIndex = typeof a.start_date === 'string' ? order.indexOf(a.start_date) : -1;
        const bIndex = typeof b.start_date === 'string' ? order.indexOf(b.start_date) : -1;
        return aIndex - bIndex;
      })
      .map((item) => {
        if (typeof item.start_date === 'string') {
          return createRow(item.start_date, item.count, item.percent);
        }
        return null;
      })
      .filter((item): item is { identifier: string; planningApplicationsNumber: number; percentageOfTotal: number } => item !== null), // Filter out null values
  } : null;

  const scraperDefaultGeocodeMethodDetails = useSelector((state: RootState) => state.scraperOverview.scraperNameAppDefaultGeocodeMethodDetails);
  const typeDataDefaultGeocodeMethod = scraperDefaultGeocodeMethodDetails != null ? {
    headers: [
      { key: 'identifier', headerName: 'Portal Geocoding' },
      { key: 'planningApplicationsNumber', headerName: '# Planning applications' },
      { key: 'percentageOfTotal', headerName: 'Percentage of total' },
    ],
    rows: scraperDefaultGeocodeMethodDetails.map((item) => createRow(item.geocode_method ?? 'Unknown', item.count, item.percent)),
  } : null;

  return (
    <RootContainer>
      <Header>
        <ScraperHeading>Statistics</ScraperHeading>
      </Header>
      <DividerStyled />
      <Body>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Content>
              <TotalPlanningApplications />
              <StatisticsTable tableData={typeDataAppState} />
              <StatisticsTable tableData={typeDataGeocodeAccuracy} />
              <StatisticsTable tableData={typeDataStartDate} />
              <StatisticsTable tableData={typeDataDefaultGeocodeMethod} />
            </Content>
          </Grid>
          <Grid item xs={6}>
            <Content>
              <StatisticsTable style={{ marginTop: 0 }} tableData={typeDataAppSize} />
              <StatisticsTable tableData={typeDataApptype} />
              <StatisticsTable tableData={typeDataGeocodeMethod} />
            </Content>
          </Grid>
        </Grid>
      </Body>
    </RootContainer>
  );
}

export default Statistics;
