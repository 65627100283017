export default function buildOverrides(palette: any, constants: any, typography?: any) {
    console.log(typography)
    return {
        MuiButton: {
            root: {
                padding: '7px 12px',
            },
            containedSizeLarge: {
                padding: '10px 16px',
            },
        },
        MuiSkeleton: {
            root: {
                borderRadius: constants.radius.sm,
                background: `${palette.grey.variant3}33`,
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: palette.grey.variant3,
                color: palette.text.primary,
                fontSize: '1em',
            },
            arrow: {
                color: palette.grey.variant3,
            },
        },
        MuiSwitch: {
            root: {
                width: '40px',
                height: '20px',
                padding: 0,
                borderRadius: '10px',
            },
            switchBase: {
                color: `${palette.text.primary} !important`,
                padding: 0,
            },
            track: {
                backgroundColor: palette.grey.variant3,
                opacity: 'initial !important',
            },
        },
        MuiAlert: {
            root: {
                // ...typography.caption,
            },
            standardInfo: {
                color: palette.info.dark,
                backgroundColor: palette.info.light,
                borderRadius: 0,
            },
            standardSuccess: {
                border: `1px solid ${palette.success.main}`,
                color: palette.primary.contrastText,
                background: `${palette.success.main}1A !important`,
                borderRadius: 0,
                // backgroundColor: 'initial'
            },
            standardWarning: {
                border: `1px solid ${palette.warning.main}`,
                color: palette.primary.contrastText,
                background: `${palette.warning.main}1A !important`,
                borderRadius: 0,
                // backgroundColor: 'initial'
            },
            standardError: {
                border: `1px solid ${palette.error.main}`,
                color: palette.primary.contrastText,
                background: `${palette.error.main}1A !important`,
                borderRadius: 0,
                // backgroundColor: 'initial'
            },
            filledInfo: {
                color: palette.info.dark,
                backgroundColor: palette.info.light,
            },
            filledSuccess: {
                // border: `1px solid ${palette.success.main}`,
                color: palette.success.contrastText,
                background: `${palette.success.main} !important`,
                // backgroundColor: 'initial'
            },
            filledWarning: {
                // border: `1px solid ${palette.warning.main}`,
                color: palette.warning.contrastText,
                background: `${palette.warning.main} !important`,
                // backgroundColor: 'initial'
            },
            filledError: {
                // border: `1px solid ${palette.error.main}`,
                color: palette.error.contrastText,
                background: `${palette.error.main} !important`,
                // backgroundColor: 'initial'
            },
        },
        MuiAlertTitle: {
            root: {
                // ...typography.subtitle1
            },
        },
        MuiLink: {
            root: {
                color: palette.primary.light,
                lineHeight: 1,
            },
        },
        MuiTableCell: {
            root: {
                borderColor: `${palette.background.border} !important`,
            },
        },
        MuiDialog: {
            paper: {
                backgroundColor: palette.background.dark,
            },
        },
        MuiDialogTitle: {
            root: {
                padding: '25px 30px',
            },
        },
        MuiDialogContent: {
            dividers: {
                padding: '25px 30px',
            },
        },
        MuiDialogActions: {
            root: {
                padding: '25px 30px',
                justifyContent: 'center',
            },
            spacing: {
                '&> :not(:first-child)': {
                    marginLeft: 30,
                },
            },
        },
        MuiMenu: {
            paper: {
                '&::-webkit-scrollbar': {
                    width: 8,
                    height: 12,
                },
                '&::-webkit-scrollbar-track': {
                    background: palette.background.main,
                    // borderRadius: 4
                },
                '&::-webkit-scrollbar-thumb': {
                    background: palette.grey.variant3,
                    // borderRadius: 4
                },
            },
            list: {
                paddingRight: '0 !important',
                width: '100% !important',
            },
        },
    }
}
