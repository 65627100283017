import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { stoneGrey } from 'Config/Theme/Common/Palette';

interface StatisticsTableProps {
  tableData: {
    headers: {
      key: string;
      headerName: string;
    }[];
    rows: {
      identifier: string;
      planningApplicationsNumber: number;
      percentageOfTotal: number;
    }[];
  } | null;
  style?: object;
}

const TableWrapper = styled(TableContainer)`
  border-left: 1px solid ${({ theme }) => theme.palette.background.border};
  border-right: 1px solid ${({ theme }) => theme.palette.background.border};
  border-top: 1px solid ${({ theme }) => theme.palette.background.border};
  border-radius: 4px;
  margin-top: 36px;
`;

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    color: theme.palette.common.white,
    backgroundColor: 'rgba(196, 196, 196, 0.10)',
  },
  '&.MuiTableCell-body': {
    color: theme.palette.common.white,
    fontSize: 14,
    borderBottom: `1px solid ${stoneGrey}`,
  },
  '&.MuiTableCell-root': {
    color: theme.palette.common.white,
    borderBottom: `1px solid ${stoneGrey}`,
  },
}));

function StatisticsTable({ tableData, style }: StatisticsTableProps) {
  if (!tableData) {
    return null;
  }

  const hasRows = tableData.rows.length > 0;

  return (
    <TableWrapper style={style ?? {}}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {tableData.headers.map((header) => (
              <StyledTableCell key={header.key}>{header.headerName}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {hasRows ? (
            tableData.rows.map((row) => (
              <StyledTableRow key={row.identifier}>
                <StyledTableCell component="th" scope="row">
                  {row.identifier}
                </StyledTableCell>
                <StyledTableCell>{row.planningApplicationsNumber.toLocaleString()}</StyledTableCell>
                <StyledTableCell>{row.percentageOfTotal}%</StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={tableData.headers.length}>
                <Typography color="textSecondary" variant="body1" align="center">
                  No data available
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}

export default StatisticsTable;
