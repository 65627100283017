export const hunterNavy = '#1B3134'
export const speedSilver = '#DBE8EA'
export const flightBlue = '#9ED6DE'
export const visionGreen = '#A9FE95'

export const featherGrey = '#3D4445'
export const midGrey = '#505A5C'
export const stoneGrey = '#8C9799'
export const rainGrey = '#C4C4C4'
export const cloudGrey = '#F0F0F0'
export const white = '#FFFFFF'

export const lilac = '#D6D6FF'
export const yellow = '#FFE5A0'
export const red = '#FF7981'
