export default {
    verticalMargin: 12,
    horizontalMargin: 10,
    auth: {
        verticalMargin: 48,
        horizontalMargin: 80
    },
    tableRowHeight: 24,
    spacing: {
        sm: 5,
        md: 10,
        lg: 20,
        xl: 30
    },
    radius: {
        sm: 6,
        md: 10,
        lg: 20
    },
    mapButton: 45,
    mapButtonDense: 30,
    sidebarNavigationWidth: 61,
    drawerWidth: 560,
    footer: 24,
    header: 74,
    headerContent: 40,
    panelHeader: 70,

    dashboardTitleHeight: 80
};