import React from 'react';
import TextField from './TextField';

// eslint-disable-next-line react/prop-types
function SelectTextField({ placeholder, customPlaceholderColor, SelectProps, children, ...otherProps }) {
  const selectProps = {};
  const defaultPlaceholderColor = '#a9a9a9'; // Default color for placeholder text

  if (placeholder) {
    selectProps.displayEmpty = true;
    selectProps.renderValue =
      (otherProps.value || '') !== ''
        ? undefined
        : () => <div style={{ color: customPlaceholderColor || defaultPlaceholderColor }}>{placeholder}</div>;
  }
  selectProps.MenuProps = {
    classes: {
      paper: 'menuPaper',
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    // ...MenuProps // Not needed?
  };

  return (
    <TextField
      select
      SelectProps={{
        ...selectProps,
        ...SelectProps,
      }}
      value={otherProps?.value}
      {...otherProps}
    >
      {children}
    </TextField>
  );
}


// const styles = {
//   placeholder: {
//     color: '#a9a9a9',
//   },
//   menuPaper: {
//     margin: '4px 0px',
//   },
// };

export default SelectTextField;
