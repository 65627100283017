export default {
    useNextVariants: true,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
        fontWeight: 400,
        fontSize: '50px',
        lineHeight: '58px',
    },
    h2: {
        fontWeight: 500,
        fontSize: '32px',
        lineHeight: '37px',
        textTransform: 'none',
    },
    h3: {
        fontWeight: 300,
        fontSize: '32px',
        lineHeight: '37px',
    },
    h4: {
        fontWeight: 'bold',
        fontSize: '18px',
    },
    h5: {
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '18px',
    },
    h6: {
        fontWeight: 'normal',
        fontSize: '16px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
    },
    body1: {
        fontWeight: 400,
        fontSize: '16px',
        letterSpacing: '0.5px',
    },
    body2: {
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '0.4px',
        lineHeight: '17px',
    },
    subtitle1: {
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '0.3px',
        lineHeight: '16px',
    },
    subtitle2: {
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: '0.4px',
        lineHeight: '14px',
    },
    caption: {
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: '0.03em',
    },
    button: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
    },
    stat1: {
        fontSize: '28px',
        fontWeight: 500,
    },
    stat2: {
        fontSize: '22px',
        fontWeight: 500,
    },
    li: {
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '30px',
        letterSpacing: '0.39px',
    },
    tab: {
        fontWeight: 500,
        fontSize: '12px',
    },
    chip: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '11px',
        borderRadius: '5px',
        minHeight: '18px',
        height: '18px',
    },
}
