/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getApi } from 'api/apiHelper'

const initialState: {
    scraperStatusFilter: string[]
    status?: string | null
    scraperOverviewData?: any
    scraperOverviewNameData?: any, 
    scraperOverviewNameStatus?: any
    scraperOverviewIdData?: any
    scraperOverviewIdStatus?: any
    scraperStatus?: string | null
    scraperDetails?: any
    scraperNameAppSizeDetails?: { app_size: string | null; count: number; percent: number }[]
    scraperNameAppSizeStatus?: any
    scraperNameAppStateDetails?: { app_state: string | null, count: number, percent: number }[]
    scraperNameAppStateStatus?: any
    scraperNameAppTypeDetails?: { app_type: string | null, count: number, percent: number }[]
    scraperNameAppTypeStatus?: any
    scraperNameAppGeocodeMethodDetails?:  { new_geocode_method: string | null, count: number, percent: number }[] 
    scraperNameAppGeocodeMethodStatus?: any
    scraperNameAppGeocodeAccuracyDetails?: { new_geocode_accuracy: string | null, count: number, percent: number }[]
    scraperNameAppGeocodeAccuracyStatus?: any
    scraperNameAppStartDateDetails?: { start_date: string | null, count: number, percent: number }[]
    scraperNameAppStartDateStatus?: any
    scraperNameAppDefaultGeocodeMethodDetails?:  { geocode_method: string | null, count: number, percent: number }[] 
    scraperNameAppDefaultGeocodeMethodStatus?: any
} = {
    scraperStatusFilter: [],
}

export const fetchScraperOverview = createAsyncThunk('scraperOverview', async () => {
    const response = await getApi('authority_overview')
    return response.data.data
})

export const fetchScraperOverviewName = createAsyncThunk(
    'scraperOverviewName',
    async (scraperName: number | string) => {
        const response = await getApi('authority_overview/overview/name', {
            params: {
                scraper_name: scraperName,
            },
        })
        return response.data.data
    },
)

export const fetchScraperOverviewId = createAsyncThunk(
    'scraperOverviewId',
    async (scraperId: number | string) => {
        const response = await getApi('authority_overview/overview/id', {
            params: {
                id: scraperId,
            },
        })
        return response.data.data
    },
)

export const fetchScraperDetails = createAsyncThunk(
    'scraperDetails',
    async (scraperName: number | string) => {
        const response = await getApi('authority_overview/scraper', {
            params: {
                scraper_name: scraperName,
            },
        })
        return response.data.data
    },
)

export const fetchScraperAppSize = createAsyncThunk(
    'scraperAppSize',
    async (scraperName: number | string) => {
        const response = await getApi('authority_overview/scraper/app_size', {
            params: {
                scraper_name: scraperName,
            },
        })
        return response.data.data
    },
)

export const fetchScraperAppState = createAsyncThunk(
    'scraperAppState',
    async (scraperName: number | string) => {
        const response = await getApi('authority_overview/scraper/app_state', {
            params: {
                scraper_name: scraperName,
            },
        })
        return response.data.data
    },
)

export const fetchScraperAppType = createAsyncThunk(
    'scraperAppType',
    async (scraperName: number | string) => {
        const response = await getApi('authority_overview/scraper/app_type', {
            params: {
                scraper_name: scraperName,
            },
        })
        return response.data.data
    },
)

export const fetchScraperGeocodeMethod = createAsyncThunk(
    'scraperGeocodeMethod',
    async (scraperName: number | string) => {
        const response = await getApi('authority_overview/scraper/geocode_method', {
            params: {
                scraper_name: scraperName,
            },
        })
        return response.data.data
    },
)


export const fetchScraperGeocodeAccuracy = createAsyncThunk(
    'scraperGeocodeAccuracy',
    async (scraperName: number | string) => {
        const response = await getApi('authority_overview/scraper/geocode_accuracy', {
            params: {
                scraper_name: scraperName,
            },
        })
        return response.data.data
    },
)

export const fetchScraperStartDate = createAsyncThunk(
    'scraperStartDate',
    async (scraperName: number | string) => {
        const response = await getApi('authority_overview/scraper/start_date', {
            params: {
                scraper_name: scraperName,
            },
        })
        return response.data.data
    },
)

export const fetchScraperDefaultGeocodeMethod = createAsyncThunk(
    'scraperDefaultGeocodeMethod',
    async (scraperName: number | string) => {
        const response = await getApi('authority_overview/scraper/default_geocode_method', {
            params: {
                scraper_name: scraperName,
            },
        })
        return response.data.data
    },
)

const scraperOverviewSlice = createSlice({
    name: 'scraperOverview',
    initialState,
    reducers: {
        setScraperStatusFilter: (state, action) => {
            state.scraperStatusFilter = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchScraperOverview.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchScraperOverview.fulfilled, (state, action) => {
                state.status = null
                state.scraperOverviewData = action.payload
            })
            .addCase(fetchScraperOverview.rejected, (state) => {
                state.status = 'failed'
            })
            .addCase(fetchScraperDetails.pending, (state) => {
                state.scraperStatus = 'loading'
            })
            .addCase(fetchScraperDetails.fulfilled, (state, action) => {
                state.scraperStatus = null
                state.scraperDetails = action.payload
            })
            .addCase(fetchScraperDetails.rejected, (state) => {
                state.scraperStatus = 'failed'
            })
            .addCase(fetchScraperAppSize.pending, (state) => {
                state.scraperNameAppSizeStatus = 'loading'
            })
            .addCase(fetchScraperAppSize.fulfilled, (state, action) => {
                state.scraperNameAppSizeStatus = null
                state.scraperNameAppSizeDetails = action.payload
            })
            .addCase(fetchScraperAppSize.rejected, (state) => {
                state.scraperNameAppSizeStatus = 'failed'
            })
            .addCase(fetchScraperAppState.pending, (state) => {
                state.scraperNameAppStateStatus = 'loading'
            })
            .addCase(fetchScraperAppState.fulfilled, (state, action) => {
                state.scraperNameAppStateStatus = null
                state.scraperNameAppStateDetails = action.payload
            })
            .addCase(fetchScraperAppState.rejected, (state) => {
                state.scraperNameAppStateStatus = 'failed'
            })
            .addCase(fetchScraperAppType.pending, (state) => {
                state.scraperNameAppTypeStatus = 'loading'
            })
            .addCase(fetchScraperAppType.fulfilled, (state, action) => {
                state.scraperNameAppTypeStatus = null
                state.scraperNameAppTypeDetails = action.payload
            })
            .addCase(fetchScraperAppType.rejected, (state) => {
                state.scraperNameAppTypeStatus = 'failed'
            })
            .addCase(fetchScraperGeocodeMethod.pending, (state) => {
                state.scraperNameAppGeocodeMethodStatus = 'loading'
            })
            .addCase(fetchScraperGeocodeMethod.fulfilled, (state, action) => {
                state.scraperNameAppGeocodeMethodStatus = null
                state.scraperNameAppGeocodeMethodDetails = action.payload
            })
            .addCase(fetchScraperGeocodeMethod.rejected, (state) => {
                state.scraperNameAppGeocodeMethodStatus = 'failed'
            })
            .addCase(fetchScraperGeocodeAccuracy.pending, (state) => {
                state.scraperNameAppGeocodeAccuracyStatus = 'loading'
            })
            .addCase(fetchScraperGeocodeAccuracy.fulfilled, (state, action) => {
                state.scraperNameAppGeocodeAccuracyStatus = null
                state.scraperNameAppGeocodeAccuracyDetails = action.payload
            })
            .addCase(fetchScraperGeocodeAccuracy.rejected, (state) => {
                state.scraperNameAppGeocodeAccuracyStatus = 'failed'
            })
            .addCase(fetchScraperStartDate.pending, (state) => {
                state.scraperNameAppStartDateStatus = 'loading'
            })
            .addCase(fetchScraperStartDate.fulfilled, (state, action) => {
                state.scraperNameAppStartDateStatus = null
                state.scraperNameAppStartDateDetails = action.payload
            })
            .addCase(fetchScraperStartDate.rejected, (state) => {
                state.scraperNameAppStartDateStatus = 'failed'
            })
            .addCase(fetchScraperOverviewName.pending, (state) => {
                state.scraperOverviewNameStatus = 'loading'
            })
            .addCase(fetchScraperOverviewName.fulfilled, (state, action) => {
                state.scraperOverviewNameStatus = null
                state.scraperOverviewNameData = action.payload
            })
            .addCase(fetchScraperOverviewName.rejected, (state) => {
                state.scraperOverviewNameStatus = 'failed'
            })
            .addCase(fetchScraperOverviewId.pending, (state) => {
                state.scraperOverviewIdStatus = 'loading'
            })
            .addCase(fetchScraperOverviewId.fulfilled, (state, action) => {
                state.scraperOverviewIdStatus = null
                state.scraperOverviewIdData = action.payload
            })
            .addCase(fetchScraperOverviewId.rejected, (state) => {
                state.scraperOverviewIdStatus = 'failed'
            })
            .addCase(fetchScraperDefaultGeocodeMethod.pending, (state) => {
                state.scraperNameAppDefaultGeocodeMethodStatus = 'loading'
            })
            .addCase(fetchScraperDefaultGeocodeMethod.fulfilled, (state, action) => {
                state.scraperNameAppDefaultGeocodeMethodStatus = null
                state.scraperNameAppDefaultGeocodeMethodDetails = action.payload
            })
            .addCase(fetchScraperDefaultGeocodeMethod.rejected, (state) => {
                state.scraperNameAppDefaultGeocodeMethodStatus = 'failed'
            })
    },
    },
)

export const { setScraperStatusFilter } = scraperOverviewSlice.actions

export default scraperOverviewSlice.reducer
