import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState: {
    noteworthyScrapersData: {
        data: {
            title: string
            description: string
            scrapers?: {
                name: string
                date_range?: string
                url?: string
            }[]
        }[]
    }
    status: string | null
    error: string
} = { noteworthyScrapersData: { data: [] }, status: '', error: '' }

export const fetchNoteworthyScrapers = createAsyncThunk('noteworthyScrapers', async () => {
    const response = await axios.get('/api/noteworthy_scrapers.json')
    return response.data
})

const noteworthyScrapersSlice = createSlice({
    name: 'noteworthyScrapers',
    initialState,
    reducers: {
        setNoteworthyScrapersState: (state, action) => {
            state.noteworthyScrapersData = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchNoteworthyScrapers.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchNoteworthyScrapers.fulfilled, (state, action) => {
                state.status = null
                state.noteworthyScrapersData = action.payload
            })
            .addCase(fetchNoteworthyScrapers.rejected, (state) => {
                state.status = 'failed'
            })
    },
})

export const { setNoteworthyScrapersState } = noteworthyScrapersSlice.actions

export default noteworthyScrapersSlice.reducer
