import { configureStore } from '@reduxjs/toolkit'

import counterReducer from 'redux/demo/demoSlice'
import scraperOverviewReducer from 'redux/scraperOverview/scraperOverviewSlice'
import fieldDictionaryReducer from './fieldDictionary/fieldDictionarySlice'
import noteworthyScrapersReducer from './noteworthyScrapers/noteworthyScrapersSlice'
import newsReducer from './news/newsSlice'

const store = configureStore({
    reducer: {
        counter: counterReducer,
        scraperOverview: scraperOverviewReducer,
        fieldDictionary: fieldDictionaryReducer,
        noteworthyScrapers: noteworthyScrapersReducer,
        news: newsReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
