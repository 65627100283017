/* eslint-disable camelcase */
import { styled } from '@mui/system';
import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { convertToTitleCase } from 'utility/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import MapboxMap from './MapView';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScraperDetails, fetchScraperOverview, fetchScraperOverviewId, fetchScraperOverviewName } from 'redux/scraperOverview/scraperOverviewSlice';
import { midGrey } from 'Config/Theme/Common/Palette';


const RootContainer = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: '20px 24px',
}));

const HeaderContainer = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 30,
  display: 'flex',
  alignItems: 'center',
}));

const BackButton = styled(ButtonBase)(({ theme }) => ({
  color: `${theme.palette.text.primary} !important`,
  '& .hover': {
    color: `${theme.palette.text.disabled} !important`,
  },
}));

const ScraperHeading = styled(Typography)({
  marginLeft: '28px !important',
  fontSize: '30px !important',
});

const ScraperSubHeading = styled(Typography)({
  marginBottom: '10px !important',
  fontSize: '26px !important',
});

const DividerStyled = styled(Divider)({
  marginTop: '20px !important',
  marginBottom: '20px !important',
  borderColor: 'white',
  opacity: '16%',
});

const BodyContainer = styled('div')({
  marginTop: '24px !important',
});

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.primary,
}));

const ListItemIconRoot = styled('div')(({ theme }) => ({
  marginLeft: 5,
  minWidth: 'initial',
  alignItems: 'center',
  color: `${theme.palette.text.primary} !important`,
}));

const Anchor = styled('a')(({ theme }) => ({
  color: theme.palette.primary.light,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const AlternateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '16px !important',
}));
function Main() {
  const navigate = useNavigate();
  const { scraperName } = useParams();

  const dispatch = useDispatch();
  const { scraperDetails, scraperStatus } = useSelector((state: any) => state.scraperOverview);
  const { scraperOverviewData } = useSelector((state: any) => state.scraperOverview);
  const { scraperOverviewNameData } = useSelector((state: any) => state.scraperOverview);
  const { scraperOverviewIdData } = useSelector((state: any) => state.scraperOverview);

  const goBack = () => {
    navigate('/authority_overview');
  };

  useEffect(() => {
    dispatch(fetchScraperDetails(scraperName as string) as any);
    dispatch(fetchScraperOverview() as any);
    dispatch(fetchScraperOverviewName(scraperName as string) as any);
    dispatch(fetchScraperOverviewId(scraperDetails?.parent_area as string) as any);
  }, [dispatch]);

  console.log('Metadata', scraperDetails);
  console.log('Overview', scraperOverviewData);
  console.log('Overview Name', scraperOverviewNameData);
  console.log('Overview Id', scraperOverviewIdData);

  let childAreas = scraperDetails?.child_area;
  if (typeof childAreas === 'string') {
    childAreas = [childAreas];
  }
  const namesOfChildArea = childAreas?.map((childArea: any) => {
    const matchingOverview = scraperOverviewData?.find((overview: any) => overview.id === childArea);
    return matchingOverview ? matchingOverview.name : null;
  }).filter((name: any) => name !== null);

  const scraperNamesOfChildArea = childAreas?.map((childArea: any) => {
    const matchingOverview = scraperOverviewData?.find((overview: any) => overview.id === childArea);
    const name = matchingOverview ? matchingOverview.name : null;
    return name ? name : null;
  }).filter((name: any) => name !== null);

  const parentArea = typeof scraperDetails?.parent_area === 'number' ? [scraperDetails?.parent_area] : [];

  const nameOfParentArea = parentArea.map((area) => {
    const matchingOverview = scraperOverviewData?.find((overview: any) => overview.id === area);
    return matchingOverview ? matchingOverview.name : null;
  }).filter((name) => name !== null);

  const coverageValue = scraperOverviewNameData?.coverage || 0;
  const limitedCoverageValue = Math.min(coverageValue, 100);

  const scraperInfo = {
    configuration: {
      warnings: {
        type: 'list',
        value: scraperOverviewNameData?.warnings ? scraperOverviewNameData?.warnings?.map((warning: any) => warning) : '',
      },
      owner: { value: scraperDetails?.provider ? scraperDetails?.provider : ''  },
      method: {
        value: 'Pdf',
      },
      timeout: { value: scraperDetails?.timeout ? `${scraperDetails?.timeout} minutes` : '' },
      min_batch: { value: '200' },
      target_min_date: { value: scraperOverviewNameData?.min_target_date ? scraperOverviewNameData?.min_target_date.slice(0, 10) : '' },
      target_max_date: { value: scraperOverviewNameData?.max_target_date ? scraperOverviewNameData?.max_target_date.slice(0, 10) : '' },
      schedules: { value: scraperDetails?.scheduled ? `${scraperDetails.scheduled[0]}, ${scraperDetails.scheduled[1]} Daily` : '' },
    },
    information: {
      last_run: { value: scraperOverviewNameData?.last_run ? new Date(scraperOverviewNameData?.last_run).toLocaleString('en-GB', { timeZone: 'UTC', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : ''},
      num_UID_Applications: {
        helper_text: 'Num UID helper text',
        value: scraperOverviewNameData?.num_uid_applications ? scraperOverviewNameData?.num_uid_applications.toLocaleString() : '',
      },
      num_applications: { value: scraperOverviewNameData?.num_full_applications ? scraperOverviewNameData?.num_full_applications.toLocaleString() : '' },
      num_UID_Failing: {
        helper_text: 'Num UID helper text',
        value: scraperOverviewNameData?.num_uid_failing ? scraperOverviewNameData?.num_uid_failing.toLocaleString() : '',
      },
      num_failing: { value: scraperOverviewNameData?.num_full_failing ? scraperOverviewNameData?.num_full_failing.toLocaleString() : '' },
      num_UID_Failed: {
        helper_text: 'Num UID helper text',
        value: scraperOverviewNameData?.num_uid_failed ? scraperOverviewNameData?.num_uid_failed.toLocaleString() : '',
      },
      num_failed: { value: scraperOverviewNameData?.num_full_failed ? scraperOverviewNameData?.num_full_failed.toLocaleString() : '' },
      coverage: {
        helper_text: 'Coverage helper text',
        value: `${limitedCoverageValue}%`,
      },
      days_behind: { value: scraperOverviewNameData?.days_behind ? scraperOverviewNameData?.days_behind : '' },
      old_percent: { value: scraperOverviewNameData?.old_percent ? `${scraperOverviewNameData?.old_percent}%` : '' },
      coverage_min_date: { value: scraperOverviewNameData?.min_coverage_date ? scraperOverviewNameData?.min_coverage_date.slice(0, 10) : '' },
      coverage_max_date: { value: scraperOverviewNameData?.max_coverage_date ? scraperOverviewNameData?.max_coverage_date.slice(0, 10) : '' },
    },
  };
  
  const handleChildAreaClick = (name: any) => {
    console.log(`Clicked child area: ${name}`);
  };

  const newGeojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: scraperDetails?.geojson,
      },
    ],
  };
  console.log(scraperNamesOfChildArea, 'CHILD NAMES');
  

  return (
    <RootContainer>
      {scraperStatus ? (
        <LinearProgress color="primary" />
      ) : (
        <>
          <HeaderContainer>
            <BackButton onClick={goBack}>
              <KeyboardBackspaceIcon fontSize="large" />
            </BackButton>
            <ScraperHeading>{scraperDetails?.name}</ScraperHeading>
            <Box
  sx={{
    width: 56,
    height: 24,
    backgroundColor:
      scraperOverviewNameData?.status === 'OK'
        ? '#A9FE95'
        : scraperOverviewNameData?.status === 'Failing'
        ? '#FF7981'
        : scraperOverviewNameData?.status === 'Inactive'
        ? '#C4C4C4'
        : scraperOverviewNameData?.status === 'Warning'
        ? '#FFE5A0'
        : scraperOverviewNameData?.status === 'None'
        ? '#C4C4C4'
        : 'unset',
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Arial, sans-serif',
    fontSize: 12,
    color: midGrey,
    marginLeft: 2,
  }}
>
  {scraperOverviewNameData?.status }
</Box>
          </HeaderContainer>
          <DividerStyled />
          <ScraperSubHeading />
          <BodyContainer>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <ContentContainer>
                  {scraperDetails?.geojson && <MapboxMap geojson={newGeojson} />}
                  <ContentContainer>
                    <Typography style={{ marginTop: 12 }}>GSS</Typography>
                    <>
                      <Anchor
                        href={`https://findthatpostcode.uk/areas/${scraperDetails?.gss}.html`}
                        onClick={() => handleChildAreaClick(name)}
                        target='_blank'
                        rel="noopener noreferrer" // Add security attribute
                      >
                        {scraperDetails?.gss}
                      </Anchor>
                    </>
                  </ContentContainer>
                  <ContentContainer>
                    <Typography style={{ marginTop: 12 }}>Parent Area</Typography>
                    {nameOfParentArea && nameOfParentArea.length > 0 ? (
                      nameOfParentArea.map((name, index) => (
                        <>
                          {typeof name === 'string' && (
                            <Anchor
                              key={name}
                              href={`${process.env.REACT_APP_BASE_URL}/authority_overview/${nameOfParentArea[index].replace(/\s/g, '')}`}
                              onClick={() => handleChildAreaClick(name)}
                            >
                              {name}
                            </Anchor>
                          )}
                        </>
                      ))
                    ) : (
                      <AlternateText>N/A</AlternateText>
                    )}
                  </ContentContainer>
                  <ContentContainer>
                    <Typography style={{ marginTop: 12 }}>Child Areas</Typography>
                    {namesOfChildArea && namesOfChildArea.length > 0 ? (
                      namesOfChildArea.map((name: any, index: any) => (
                        <>
                          {typeof name === 'string' && (
                            <Anchor
                              key={name}
                              href={`${process.env.REACT_APP_BASE_URL}/authority_overview/${scraperNamesOfChildArea[index].replace(/\s/g, '')}`}
                              onClick={() => handleChildAreaClick(name)}
                            >
                              {name}
                            </Anchor>
                          )}
                        </>
                      ))
                    ) : (
                      <AlternateText>N/A</AlternateText>
                    )}
                  </ContentContainer>
                  <ContentContainer>
                    <Typography style={{ marginTop: 12 }}>Authority Url</Typography>
                    <Anchor
                      href={scraperDetails?.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {scraperDetails?.website}
                    </Anchor>
                  </ContentContainer>
                  <ContentContainer>
                    <Typography style={{ marginTop: 12 }}>Portal Url</Typography>
                    <Anchor
                      href={scraperDetails?.search_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {scraperDetails?.search_url}
                    </Anchor>
                  </ContentContainer>
                </ContentContainer>
              </Grid>
              <Grid item xs={8}>
                <ContentContainer>
                  <ScraperSubHeading>{scraperDetails?.long_name}</ScraperSubHeading>
                  {scraperOverviewNameData?.internal_comments != null ? scraperOverviewNameData?.internal_comments : ''}
                  <DividerStyled />
                  <div>
                    {scraperInfo &&
                      Object.entries(scraperInfo).map(([key, value]) => {
                        return (
                          <Box key={key} display="flex" flexDirection="column">
                            <Typography sx={{
                              padding: '4px'
                            }}>
                              <b>{convertToTitleCase(key)}</b>
                            </Typography>
                            <DividerStyled />
                            {value &&
                              Object.entries(value).map(([rowTitle, rowValue]: [string, {helper_text?: string, type?: string, value?: string[] | string }]) => {
                                return (
                                  <>
                                    <Box
                                      key={rowTitle}
                                      display="flex"
                                      alignContent="center"
                                      padding='0px 16px'
                                    >
                                      <Box
                                        width="30%"
                                        display="flex"
                                        alignContent="center"
                                      >
                                        {convertToTitleCase(rowTitle)}
                                        {rowValue?.helper_text ? (
                                          <ListItemIconRoot>
                                            <div>
                                              {/* Add your custom icon component here */}
                                            </div>
                                          </ListItemIconRoot>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                      <Box sx={{
                                        marginTop: 'unset !important'
                                      }}>
                                        {rowValue?.type === 'list' ? (
                                          <ul style={{marginTop: '0px'}}>
                                            {Array.isArray(rowValue?.value) ? rowValue?.value.map(
                                              (e) => (
                                                <li key={e}>
                                                  <Typography>
                                                    {e}
                                                  </Typography>
                                                </li>
                                              ),
                                            ) : <></>}
                                          </ul>
                                        ) : (
                                          <Typography>
                                            {rowValue?.value}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>
                                    <DividerStyled />
                                  </>
                                );
                              })}
                          </Box>
                        );
                      })}
                  </div>
                </ContentContainer>
              </Grid>
            </Grid>
          </BodyContainer>
        </>
      )}
    </RootContainer>
  );
}

export default Main;
