export default function buildComponents(palette: any, constants: any) {
    return {
        scrollable: {
            '&::-webkit-scrollbar': {
                width: 8,
                height: 12,
            },
            '&::-webkit-scrollbar-track': {
                background: palette.background.main,
                // borderRadius: 4
            },
            '&::-webkit-scrollbar-thumb': {
                background: palette.grey.variant3,
                // borderRadius: 4
            },
        },
        mapButton: {
            backgroundColor: palette.background.dark + ' !important',
            color: palette.grey.variant3,
            borderRadius: constants.radius.sm,
            width: constants.mapButton,
            height: constants.mapButton,
        },
        mapButtonDense: {
            // backgroundColor: `${primaryVariant1} !important`,
            backgroundColor: palette.background.dark + ' !important',
            color: palette.text.primary,
            width: constants.mapButtonDense,
            height: constants.mapButtonDense,
        },
    }
}
