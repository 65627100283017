import { Pagination, PaginationItem } from '@mui/material'
import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid'
import { visionGreen } from 'Config/Theme/Common/Palette'

function CustomPagination() {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
        <Pagination
            sx={{
                marginLeft: 'auto',
                marginRight: 'auto',
                '& .MuiPaginationItem-root': {
                    border: 'none !important',
                    color: 'white !important',
                },
                '& .Mui-selected': {
                    backgroundColor: `${visionGreen} !important`,
                    color: 'black !important',
                },
            }}
            color='primary'
            variant='outlined'
            shape='rounded'
            page={page + 1}
            count={pageCount}
            showFirstButton
            showLastButton
            renderItem={(props2: any) => <PaginationItem {...props2} disableRipple />}
            onChange={(event: React.ChangeEvent<unknown>, value: number) =>
                apiRef.current.setPage(value - 1)
            }
        />
    )
}

export default CustomPagination