import {
    hunterNavy,
    speedSilver,
    flightBlue,
    visionGreen,
    featherGrey,
    midGrey,
    stoneGrey,
    rainGrey,
    cloudGrey,
    white,
    lilac,
    // yellow,
    // red
} from '../Common/Palette'

// const backgroundMain = white
const textPrimary = featherGrey

export default {
    type: 'light',
    primary: {
        main: visionGreen,
        light: visionGreen,
        contrastText: textPrimary,
    },
    secondary: {
        main: flightBlue,
        light: speedSilver,
    },
    tertiary: {
        main: lilac,
    },
    info: {
        main: stoneGrey,
        light: `${cloudGrey}1A`,
        dark: textPrimary,
        contrastText: textPrimary,
    },
    success: {
        main: '#A9FE95',
        light: '#edf7ed',
        dark: '#1e4620',
        contrastText: textPrimary,
    },
    warning: {
        main: '#FFE5A0',
        light: '#fff4e5',
        dark: '#663c00',
        contrastText: textPrimary,
    },
    error: {
        main: '#FF7981',
        light: '#fdecea',
        dark: '#611a15',
        contrastText: textPrimary,
    },
    background: {
        paper: white,
        default: cloudGrey,
        dark: featherGrey,
        main: cloudGrey,
        light: rainGrey,
        lighter: cloudGrey,
        contrastText: textPrimary,
        border: stoneGrey,
    },
    grey: {
        variant1: cloudGrey,
        variant2: rainGrey,
        variant3: stoneGrey,
        variant4: midGrey,
        variant5: featherGrey,
    },
    text: {
        primary: textPrimary,
        secondary: hunterNavy,
        inactive: stoneGrey,
    },
    icon: {
        main: stoneGrey,
    },
}
