import {
    hunterNavy,
    speedSilver,
    flightBlue,
    visionGreen,
    featherGrey,
    midGrey,
    stoneGrey,
    rainGrey,
    cloudGrey,
    white,
    // lilac,
    // yellow,
    // red,
} from '../Common/Palette'

const textPrimary = white
const textSecondary = rainGrey

export default {
    type: 'dark',
    primary: {
        main: hunterNavy,
        light: flightBlue,
        contrastText: textPrimary,
    },
    secondary: {
        main: visionGreen,
        light: speedSilver,
    },
    info: {
        main: stoneGrey,
        light: `${cloudGrey}1A`,
        dark: textPrimary,
        contrastText: textPrimary,
    },
    success: {
        main: '#A9FE95',
        light: '#edf7ed',
        dark: '#1e4620',
        contrastText: featherGrey,
    },
    warning: {
        main: '#FFE5A0',
        light: '#fff4e5',
        dark: '#663c00',
        contrastText: featherGrey,
    },
    error: {
        main: '#FF7981',
        light: '#fdecea',
        dark: '#611a15',
        contrastText: featherGrey,
    },
    background: {
        paper: '#5F696B',
        default: '#272727',
        dark: featherGrey,
        main: midGrey,
        light: stoneGrey,
        lighter: cloudGrey,
        contrastText: textPrimary,
        border: stoneGrey,
    },
    grey: {
        variant1: featherGrey,
        variant2: midGrey,
        variant3: stoneGrey,
        variant4: rainGrey,
        variant5: cloudGrey,
    },
    text: {
        primary: textPrimary,
        secondary: textSecondary,
        inactive: stoneGrey,
    },
    icon: {
        main: stoneGrey,
    },
}
