import { styled } from '@mui/system';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// import {
//   Divider,
//   Drawer,
//   InputAdornment,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   TextField,
// } from '@mui/material';
import React from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {  hunterNavy, rainGrey, visionGreen } from 'Config/Theme/Common/Palette';
// import SearchIcon from '@mui/icons-material/Search';

const drawerWidth = 260;

const Root = styled('div')({
  display: 'flex',
  height: '100%',
});

const DrawerContainer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  height: '100%',
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    background: theme.palette.primary.main,
  },
}));

const Logo = styled('img')({
  // marginRight: 'auto',
  cursor: 'pointer',
  padding: '20px 20px 0px 20px',
  height: 30, // Decrease the height of the logo
  width: 'auto', // Allow the width to adjust based on the height
});
// const SearchContainer = styled('div')({
//   padding: '24px 20px 16px 20px',
// });

// const SearchField = styled(TextField)({
//   background: 'white',
//   color: 'black',
// });

const BetaBox = styled(Box)({
  height: '16px',
  width: '44px',
  backgroundColor: visionGreen,
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Arial, sans-serif',
  fontSize: 12,
  color: 'black',
  marginLeft: 2,
  marginTop: '18px'
});

const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.main,
  padding: 36,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
  maxHeight: '100vh',
  overflow: 'auto',
}));

const ListItemContainer = styled(ListItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: rainGrey,
    borderRadius: '4px',
    color: hunterNavy,
    '& .MuiListItemIcon-root': {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
      color: hunterNavy
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
    color: 'white'
  },
  cursor: 'pointer',
}));

interface RouteConfig {
  isTab: boolean;
  route: string;
  icon: React.ReactNode;
  title: string;
  element: React.ReactNode;
  root?: string;
}

interface SidebarProps {
  routes: Record<string, RouteConfig>;
}

function Sidebar(props: SidebarProps) {
  const { routes } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Root>
    <DrawerContainer variant="permanent">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Logo
          src="https://landhawk.s3.eu-west-1.amazonaws.com/logos/lh-logo.svg"
          onClick={() => navigate('/')}
        />
        <BetaBox>
          BETA
        </BetaBox>
      </div>
        {/* <SearchContainer>
          <SearchField
            variant="outlined"
            id="input-with-icon-textfield"
            placeholder="Search for scrapers"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </SearchContainer> */}
        <Divider />
        <List sx={{
              paddingLeft: '12px',
              paddingRight: '12px'
        }}>
          {routes &&
            Object.entries(routes).map(([key, value]) => {
              return value.isTab ? (
                <ListItemContainer
                  key={key}
                  selected={pathname.includes(value.route)}
                //   button
                  onClick={() => navigate(value.route)}
                >
                  <ListItemIcon>{value.icon}</ListItemIcon>
                  <ListItemText primary={value.title} />
                </ListItemContainer>
              ) : null;
            })}
        </List>
      </DrawerContainer>
      <Content>
        <Routes>
          <Route path="/" element={<Navigate to="/authority_overview" />} />
          {routes &&
            Object.entries(routes).map(([key, value]) => {
              return (
                <Route
                  index={value.root === 'authority_overview'}
                  key={key}
                  path={value.route}
                  element={value.element}
                />
              );
            })}
          <Route
            path="*"
            element={
              <div>
                <h2>404 Page not found</h2>
              </div>
            }
          />
        </Routes>
      </Content>
    </Root>
  );
}

export default Sidebar;