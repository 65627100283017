import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState: {
    fieldDictionaryData: {
        data: {
            field_name: string
            field_description: string
            values: {
                field_name: string
                field_description: string
            }[]
        }[]
    }
    status: string | null
    error: string
} = { fieldDictionaryData: { data: [] }, status: '', error: '' }

export const fetchFieldDictionary = createAsyncThunk('fieldDictionary', async () => {
    const response = await axios.get('/api/field_dictionary.json')
    return response.data
})

const fieldDictionarySlice = createSlice({
    name: 'fieldDictionary',
    initialState,
    reducers: {
        setFieldDictionaryState: (state, action) => {
            state.fieldDictionaryData = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFieldDictionary.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchFieldDictionary.fulfilled, (state, action) => {
                state.status = null
                state.fieldDictionaryData = action.payload
            })
            .addCase(fetchFieldDictionary.rejected, (state) => {
                state.status = 'failed'
            })
    },
})

export const { setFieldDictionaryState } = fieldDictionarySlice.actions

export default fieldDictionarySlice.reducer
