export default function ScraperOverviewIcon() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
        >
            <g clipPath='url(#clip0_1939_124195)'>
                <path
                    d='M16.6667 5V16.6667H5V18.3333H16.6667C17.5833 18.3333 18.3333 17.5833 18.3333 16.6667V5H16.6667Z'
                    fill='currentColor'
                />
                <path
                    d='M13.3332 1.66666H3.33317C2.4165 1.66666 1.6665 2.41666 1.6665 3.33332V13.3333C1.6665 14.25 2.4165 15 3.33317 15H13.3332C14.2498 15 14.9998 14.25 14.9998 13.3333V3.33332C14.9998 2.41666 14.2498 1.66666 13.3332 1.66666ZM7.49984 13.3333H3.33317V9.16666H7.49984V13.3333ZM13.3332 13.3333H9.1665V9.16666H13.3332V13.3333ZM13.3332 7.49999H3.33317V3.33332H13.3332V7.49999Z'
                    fill='currentColor'
                />
            </g>
            <defs>
                <clipPath id='clip0_1939_124195'>
                    <rect width='20' height='20' fill='currentColor' />
                </clipPath>
            </defs>
        </svg>
    )
}
