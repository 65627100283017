import { styled } from '@mui/system';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  MenuItem,
  Grid,
  Box,
  IconButton,
  useTheme
} from '@mui/material';
// import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';

import { GridApi, GridColDef } from '@mui/x-data-grid';
import SelectTextField from 'components/Common/UI/SelectTextField';
import { useMemo, useState } from 'react';
import { flightBlue, midGrey, rainGrey, stoneGrey, visionGreen } from 'Config/Theme/Common/Palette';
import { GridStateColDef } from '@mui/x-data-grid/internals';
import BuildIcon from '@mui/icons-material/Build';
// import { CustomTheme } from 'Config/Theme/Common/CustomTheme.types';

const PopoverButton = styled(Button)(({ theme }) => ({
  background: `${theme.palette.background.dark} !important`,
  padding: '8px 20px',
  marginTop: '4px',
}));

const DialogColor = styled(DialogContent)(({ theme }) => ({
  background: theme.palette.background.main,
  color: theme.palette.text.primary,
  borderTop: '1px solid white',
  borderBottom: '1px solid white'
}));

const CancelIconButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const AddNewColumnButton = styled(Typography)(({ theme }) => ({
  marginTop: 8,
  color: flightBlue,
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  },
}));

interface ColumnOptionsOverlayProps {
  dataGridRef: GridApi;
  columns: GridColDef[];
}

function ColumnOptionsOverlay(props: ColumnOptionsOverlayProps) {
  const { dataGridRef } = props

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const handleShow = () => {
      setIsDialogOpen(true)
      // dataGridRef.setColumnVisibility('status', false)
  }
  const handleClose = () => setIsDialogOpen(false)
  const toggleDialog = () => setIsDialogOpen((p) => !p)

  const [visibleColumns, setVisibleColumns] = useState(dataGridRef.getVisibleColumns())

  const visibleColumnFields: string[] = visibleColumns.map((e) => e.headerName ?? 'status');

  const remainingColumns = useMemo(
      () =>
          dataGridRef
              .getAllColumns()
              .map((column) => column)
              .filter((column) => !visibleColumnFields.includes(column.headerName ?? 'status')),
      [visibleColumnFields, visibleColumns],
  );
  

  const theme = useTheme()

  return (
    <>
      <PopoverButton variant="contained" onClick={handleShow} sx={{
        textTransform: 'unset !important'
      }}>
        Column Options
      </PopoverButton>
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle  sx={{
        background: midGrey,
      }}>
        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', textTransform: 'unset !important', fontSize: '18px', fontWeight: 'bold', padding: '6px 24px' }}>
    <BuildIcon sx={{
      fontSize: '24px',
      paddingRight: '8px'
    }}/>
    Column Options
    <Box sx={{ marginLeft: 'auto'  }}>
        <IconButton
            onClick={toggleDialog}
            sx={{ padding: 0, marginLeft: '8px', color: theme.palette.text.secondary, }}
        >
            <CloseIcon />
        </IconButton>
    </Box>
</Typography>
        </DialogTitle>
        <DialogColor dividers
        sx={{
          borderTop: '1px solid rgba(255, 255, 255, 0.16)',
          borderBottom: '1px solid rgba(255, 255, 255, 0.16)'
        }}>
          <Grid container spacing={2}>
            {visibleColumns.map((column) => (
              <>
                <Grid item xs={10}>
                  <SelectTextField
                    placeholder={column.headerName}
                    customPlaceholderColor={'white'}
                    defaultValue={column.headerName}
                    SelectProps={{}}
                    fullWidth
                    sx={{
                      '& .MuiInputBase-input': {
                        color: `${theme.palette.info.main} !important`,
                    },
                    '& .MuiOutlinedInput-root': {
                        paddingRight: '4px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: `${stoneGrey}`,
                  },
                    '&:focus': {
                        outline: 'none',
                      },
                      '&:focus-visible': {
                        outline: 'none !important',
                        borderColor: `${theme.palette.secondary.main}`
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${theme.palette.secondary.main} !important`,
                      },
                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                      },
                      '& .MuiInputBase-root-MuiOutlinedInput-root:focus': {
                        outlineColor: `${theme.palette.secondary.main} !important`,
                    }
                    }}
                  >
                    {remainingColumns.map((e: any) => (
                      <MenuItem
                          key={e.field}
                          value={e.field}
                          onClick={() => {
                              console.log(e, 'target inside func');
                              dataGridRef.setColumnVisibility(column.field, false);
                              dataGridRef.setColumnVisibility(e.field, true);
                              // setColumns(dataGridRef.getAllColumns());
                              setVisibleColumns(dataGridRef.getVisibleColumns());
                          }}
                      >
                          {e.headerName || 'Status'}
                      </MenuItem>
                  ))}
                  </SelectTextField>
                </Grid>
                <Grid item xs={2} 
                sx={{
                  paddingTop: '20px !important'
                }}>
                  <CancelIconButton
                    onClick={() => {
                      dataGridRef.setColumnVisibility(column.field, false);
                      setVisibleColumns(dataGridRef.getVisibleColumns());
                    }}
                  >
                    <CloseIcon />
                  </CancelIconButton>
                </Grid>
              </>
            ))}
          </Grid>
          <Box display="flex" justifyContent="end" marginTop={2}>
            <AddNewColumnButton
              onClick={() => {
                setVisibleColumns((p) => [...p, {} as GridStateColDef]);
              }}
            >
              + Add new column
            </AddNewColumnButton>
          </Box>
        </DialogColor>
        <DialogActions sx={{ background: midGrey, justifyContent: 'space-between', paddingLeft: '20px',  paddingRight: '20px', paddingTop: '15px' }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              color: rainGrey,
              border: `1px solid ${rainGrey}`,
              backgroundColor: 'transparent',
              width: '260px',
              height: '45px',
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={toggleDialog}
            sx={{
              color: 'black',
              backgroundColor: visionGreen,
              width: '260px',
              height: '45px',
            }}
          >
            DONE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ColumnOptionsOverlay;
