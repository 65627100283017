import { createTheme } from '@mui/material/styles'

import buildComponents from './Components'
import buildOverrides from './Overrides'

export default function buildTheme(palette: any, typography: any, constants: any) {
    return createTheme({
        palette: { ...palette },
        typography: { ...typography },
        constants: { ...constants },
        spacing: (factor: number) => [0, 5, 10, 15, 20, 25, 30][factor],
        components: buildComponents(palette, constants),
        overrides: buildOverrides(palette, constants, typography),

        // Slow-mo transitions for testing
        // transitions: {
        //     duration: {
        //         shortest: 150 * 10,
        //         shorter: 200 * 10,
        //         short: 250 * 10,
        //         standard: 300 * 10,
        //         complex: 375 * 10,
        //         enteringScreen: 225 * 10,
        //         leavingScreen: 195 * 10,
        //     }
        // },

        zIndex: {
            page: 100,
            popover: 1299,
        },
    } as any)
}
