/* eslint-disable quotes */
/* eslint-disable camelcase */
import { Box, Typography, LinearProgress, Grid, Divider } from '@mui/material';
import { rainGrey } from 'Config/Theme/Common/Palette';
// import { RootContainer } from 'components/Common/UI/PageLayout';
import FieldDictionaryIcon from 'components/CustomIcons/FieldDictionary';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFieldDictionary } from 'redux/fieldDictionary/fieldDictionarySlice';
import { AppDispatch, RootState } from 'redux/store';
import { styled } from '@mui/system';


const leftSpacing = 3;
const rightSpacing = 12 - leftSpacing;

const RootContainer = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  width: '60vw',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: theme.palette.text.primary,
  padding: '24px 32px',
}));

function FieldDictionary() {
  const dispatch = useDispatch<AppDispatch>();
  // const theme: CustomTheme = useTheme();
  const {
    fieldDictionaryData: { data },
    status,
  } = useSelector((state: RootState) => state.fieldDictionary);
  
  useEffect(() => {
    dispatch(fetchFieldDictionary());
  }, []);
  
  return (
    <RootContainer>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px !important',
        marginLeft: 8,
        fontSize: '16px !important',
      }}>
        <Box sx={{
          color: rainGrey,
        }}><FieldDictionaryIcon width="30" height="30"  /></Box>
        <Typography variant="body1" sx={{ marginLeft: '8px !important' }}>
          FIELD DICTIONARY
        </Typography>
      </Box>
      <Divider sx={{
          marginTop: '20px !important',
          borderColor: 'white',
          opacity: '16%',
      }} />
      {status ? (
        <LinearProgress color="primary" />
      ) : (
        <>
          <Grid sx={{
            marginTop: '4px !important',
            marginBottom: '36px !important',
            padding: '0px 24px !important',
            maxHeight: '75vh',
            overflow: 'auto',
          }} container spacing={2}>
            <Grid item xs={leftSpacing}>
              <Typography variant="body1">Field Name</Typography>
            </Grid>
            <Grid item xs={rightSpacing}>
              <Typography variant="body1">Explanation</Typography>
            </Grid>
          </Grid>
          <Divider sx={{
          marginTop: '20px !important',
          borderColor: 'white',
          opacity: '16%',
      }} />
          <Grid sx={{
            marginTop: '4px !important',
            marginBottom: '8px !important',
            padding: '0px 24px !important',
            maxHeight: '75vh',
            overflow: 'auto',
          }} container spacing={2}>
            {data.map((field) => {
              return (
                <>
                  <Grid item xs={leftSpacing} sx={{
                    padding: '0px 24px',
                    paddingTop: '24px !important'
                  }}>
                    {field.field_name}
                  </Grid>
                  <Grid item xs={rightSpacing} sx={{
                    padding: '0px 24px',
                    paddingTop: '24px !important'
                  }}>
                    {field.field_description}
                  </Grid>
                  {field?.values ? (
                    field.values.map((subField) => {
                      return (
                        <>
                          <Grid item xs={leftSpacing} sx={{
                            padding: '0px 8px',
                            paddingTop: '8px !important'
                          }}>
                            <Typography variant="body1" sx={{ marginLeft: '24px !important', wordBreak: 'break-all' }}>
                              <li>{subField.field_name}</li>
                            </Typography>
                          </Grid>
                          <Grid item xs={rightSpacing} sx={{
                            padding: '0px 8px',
                            paddingTop: '8px !important'
                          }}>
                            {subField.field_description}
                          </Grid>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </Grid>
        </>
      )}
    </RootContainer>
  );
}

export default  FieldDictionary;
