import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState: {
    newsData: {
        data: {
            author: string
            description: string
            isNew: true
            date: string
        }[]
    }
    status: string | null
    error: string
} = { newsData: { data: [] }, status: '', error: '' }

export const fetchNews = createAsyncThunk('news', async () => {
    const response = await axios.get('/api/news.json')
    return response.data
})

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNewsState: (state, action) => {
            state.newsData = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchNews.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchNews.fulfilled, (state, action) => {
                state.status = null
                state.newsData = action.payload
            })
            .addCase(fetchNews.rejected, (state) => {
                state.status = 'failed'
            })
    },
})

export const { setNewsState } = newsSlice.actions

export default newsSlice.reducer
