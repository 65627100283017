import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import bbox from '@turf/bbox';

const MapContainer = styled('div')`
  width: 100% !important;
  height: 300px;
`;

const MapboxMap = ({ geojson }: { geojson: any }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);

  const mapboxAccessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';

  useEffect(() => {
    mapboxgl.accessToken = mapboxAccessToken; // Replace with your Mapbox access token

    if (mapContainer.current) {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11', // Replace with your preferred map style
        dragPan: false,
        scrollZoom: false,
        doubleClickZoom: false,
        attributionControl: false, 
        maxBounds:[[-20, 49], [11, 61]]
      });

      map.on('load', () => {
        // Add the GeoJSON data source
        map.addSource('outline-source', {
          type: 'geojson',
          data: geojson,
        });

        map.addLayer({
          id: 'outline-fill',
          type: 'fill',
          source: 'outline-source', // reference the data source
          layout: {},
          paint: {
            'fill-color': '#ff0000',
            'fill-opacity': 0.2,
          },
        });

        // Add a layer to display the outline
        map.addLayer({
          id: 'outline-line',
          type: 'line',
          source: 'outline-source',
          paint: {
            'line-color': 'red', // Change the outline color as desired
            'line-width': 2, // Change the line width as desired
          },
        });

        const boundingBox = bbox(geojson);
        map.fitBounds(boundingBox as LngLatBoundsLike, { padding: 20 });
      });

      // Cleanup when the component unmounts
      return () => {
        map.remove();
      };
    }
  }, [geojson, mapboxAccessToken]);

  return <MapContainer ref={mapContainer} />;
};

export default MapboxMap;
